import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance"
import { log } from "util";


// export const getAllConcour = createAsyncThunk(
//     "manageConcour/getAllConcour",
//     async (thunkAPI) => {
//         const url = "http://127.0.0.1:5000/api/concour/getAllConcour"
//         axiosInstance.get(url)
//         .then(resp => resp.json)
//         .catch(error => thunkAPI.rejectWithValue(error.message))
//     }
// )
//
export const getAllConcour = createAsyncThunk(
    "manageConcour/getAllConcour",
    async (thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/settings/getAllSession`


        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*"
        }
      try {
let respond
        await fetch(url,{
            method:"get",
            headers:headers
        })
        .then(res => res.json())
        .then(data => {
            respond = data
            // console.log(data)
        })
        .catch(err => console.log)
            console.log(respond.session)

        return respond.session.reverse()
    }
    catch(error) {
        console.log("the error is :")
        console.error(error);
        
        thunkAPI.rejectWithValue(error.message)
    }
    }
)

const manageConcour = createSlice({
    name: "manageConcour",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getAllConcour.pending)
        .addCase(getAllConcour.fulfilled, (state, action) => {
            // console.log("fulfilled")
            state = action.payload
            // console.log(state)
            return state
        })
        .addCase(getAllConcour.rejected, (state,action)=>{
            console.log(action,"rejected")
        })
    }
})

export default manageConcour.reducer    