import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import axiosInstance from "../../../utils/AxiosInstance"
import produce from 'immer';
import axios from "axios"

const personalInfo = {
    _id:"",
    Fname: "", 
    Lname: "",
    Dob: "",
    Email: "",
    Address: "",
    Gender: "",
    nantionanlity: "",
    region: "",
    phoneNumber: "" 
}
const personnelFunction = {
    department:"", title:"", role:"", contract:"", status:""
}
const roles = []
const initialState = {personalInfo, personnelFunction,roles}

export const sendUpdate = createAsyncThunk(
    "updatePersonnel/sendUpdate",
    async ({personalInfo,personnelFunction,roles},thunkAPI) => {
        // const url = "http://35.188.194.141:5000/api/personnel/updatePerso/" + personalInfo._id
        const body = {
            _id:personalInfo._id,
            Fname:personalInfo.Fname,
            Lname:personalInfo.Lname,
            Dob:personalInfo.Dob,
            Gender:personalInfo.Gender,
            Email:personalInfo.Email,
            phoneNumber:personalInfo.phoneNumber,
            Address:personalInfo.Address,
            nantionanlity:personalInfo.nantionanlity,
            region:personalInfo.region,
            department:personnelFunction.department,
            role:personnelFunction.role,
            title:personnelFunction.title,
            contract:personnelFunction.contract,
            status:personnelFunction.status,
        }
        console.log("body",body);
        const url = `${process.env.REACT_APP_API_URL}/personnelData/` + personalInfo._id
        console.log("url:",url);
        axiosInstance.put(url, body)
        .then(res => console.log(res))
        .catch(err => thunkAPI.rejectWithValue(err.response))
        // fetch("http://35.188.194.141:5000/api/personnel/updatePerso/" + personalInfo._id, {
        //       method: 'PUT',
        //       body: JSON.stringify(body),
        //       headers:{
        //         'Content-Type':'application/json'
        //     },
        //     })
        //     .then(res => res.json())
        //     .then(updatedData => {
        //       console.log('Data updated:', updatedData);
        //     })
        //     .catch(error => {
        //       console.error('Error modifying data:', error);
        //     });
    }
)

const personnelCreationSlice = createSlice({
    name: "updatePersonnel",
    initialState,
    reducers: {
        PersonnelUpdateInfo: (state, action) => {
            if(action.payload._id) 
                state.personalInfo._id = action.payload._id
            if(action.payload.Lname) 
                state.personalInfo.Lname = action.payload.Lname
            if(action.payload.Fname) 
                state.personalInfo.Fname = action.payload.Fname
            if(action.payload.Dob) 
                state.personalInfo.Dob = action.payload.Dob                
            if(action.payload.Address) 
                state.personalInfo.Address = action.payload.Address
            if(action.payload.Email) 
                state.personalInfo.Email = action.payload.Email
            if(action.payload.Gender)
                state.personalInfo.Gender = action.payload.Gender
            if(action.payload.nantionanlity)
                state.personalInfo.nantionanlity = action.payload.nantionanlity
            if(action.payload.region)
                state.personalInfo.region = action.payload.region
            if(action.payload.phoneNumber)
                state.personalInfo.phoneNumber = action.payload.phoneNumber
                console.log("personalInfo:",action.payload);
            return         
        },
        PersonnelUpdateFunction: (state, action) => {
            if(action.payload.department)
                state.personnelFunction.department = action.payload.department
            if(action.payload.role)
                state.personnelFunction.role = action.payload.role
            if(action.payload.title)
                state.personnelFunction.title = action.payload.title
            if(action.payload.contract)
                state.personnelFunction.contract = action.payload.contract
            if(action.payload.status)
                state.personnelFunction.status = action.payload.status    
                console.log("personaluodateFunction:",action.payload);
                return          
        },
        StatusUpate: (state, action) => {
            const body = {
                _id:action.payload._id,
                Fname:action.payload.Fname,
                Lname:action.payload.Lname,
                Dob:action.payload.Dob,
                Gender:action.payload.Gender,
                Email:action.payload.Email,
                phoneNumber:action.payload.phoneNumber,
                Address:action.payload.Address,
                nantionanlity:action.payload.nantionanlity,
                region:action.payload.region,
                department:action.payload.department,
                role:action.payload.role,
                title:action.payload.title,
                contract:action.payload.contract,
                status:action.payload.status,
            }
            console.log("body",body);
            const url = `${process.env.REACT_APP_API_URL}/personnelData/` + action.payload._id
            // const url = "http://35.188.194.141:5000/api/personnel/updatePerso/" + personalInfo._id
            console.log("url:",url);
            axiosInstance.put(url, body)
            .then(res => console.log(res))
            .catch(err => (err.response))
            // fetch(url, {
            //   method: 'PUT',
            //   body: JSON.stringify(body),
            //   headers:{
            //     'Content-Type':'application/json'
            // },
            // })
            // .then(res => res.json())
            // .then(updatedData => {
            //   console.log('Data updated:', updatedData);
            // })
            // .catch(error => {
            //   console.error('Error modifying data:', error);
            // });
        },
        DeletePersonnel: (state,action) => {
            // const url = "http://35.188.194.141:5000/api/personnel/deletePerso/"+ action.payload
            const url = `${process.env.REACT_APP_API_URL}/personnelData/`+ action.payload
            console.log("url:",url);
            axiosInstance.delete(url)
            .then(res => console.log(res))
            .catch(err => (err.response))
            // fetch('http://localhost:8000/api/personnelData/'+ action.payload, { method: 'DELETE' })
            // .then(res => res.json())
            // .catch(error => {
            //   console.error(error);
            // });
        }
    },
    extraReducers: (builder) => {
        builder 
        .addCase(sendUpdate.pending)
        .addCase(sendUpdate.fulfilled, (state, action) => {
            console.log("Fulfilled")
            console.log("action.Payload",action.payload);
            return produce(state,(PersonnelFunction,PersonnelUpdateInfo)=>{
                PersonnelFunction.inputValue = action.payload;
                PersonnelUpdateInfo = action.payload;
            });
        })
        .addCase(sendUpdate.rejected, (state, action) => {
            console.log("Rejected !")
        })
    }
    
})

export const {PersonnelUpdateInfo,PersonnelUpdateFunction,StatusUpate,DeletePersonnel} = personnelCreationSlice.actions
export default personnelCreationSlice.reducer