import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
const initAll = []
export const getAllCandidates = createAsyncThunk(
    "manageCandidates/getAllCandidates",
    async (thunkAPI) => {
        // const url = "http://35.188.194.141:5000/api/candidate/getAllCandidates"
        // const url = "http://localhost:5000/api/student/getAllCandidatesgetStudents"
        const url = `${process.env.REACT_APP_API_URL}/candidate/getAllcandidates`

        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*"
        }
      try {
       
        let response=await fetch(url, {
            method: "GET",
            headers: headers,
        })
        .then(res => res.json())
        .then(data => {
            console.log("data in man candidate",data)
            return data
            // response= data.candidates
        })

        // response =response.json()
        // console.log(response)
        return response.candidates
    }
    catch(error) {
        thunkAPI.rejectWithValue(error.message)
    }
    }
)


const manageCandidates = createSlice({
    name: "manageCandidates",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getAllCandidates.pending)
        .addCase(getAllCandidates.fulfilled, (state, action)=>{
            console.log("Fulfilled")
            state = action.payload
            return state
        })
        .addCase(getAllCandidates.rejected, (state, action) => {
            console.log("Rejected")
            console.log(action)
        })
    }
})

export default manageCandidates.reducer