import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance"

export const getAllCenters = createAsyncThunk(
    "manageCenters/getAllCenters",
    async (thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/settings/getAllCenter`

        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*"
        }
      try {
        const response = await fetch(url, {
            method: "GET",
            headers: headers,
        })
        if(response){
            // console.log(response);
            return response.json()
        }
    }
    catch(error) {
        thunkAPI.rejectWithValue(error.message)
        throw new Error(error)
    }
    }
)


const manageCenters = createSlice({
    name: "manageCenters",
    initialState: [],
    reducers: {
        deleteCenter:async(state ,action)=>{
            let id = action.payload
            // console.log(id)
            
            const url = `${process.env.REACT_APP_API_URL}/settings/deleteCenter`
            
            fetch(url, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({id:id}) // Convert the object to a string
              })
              .then(res => res.json())
                .then(data => {       
                    // console.log('DATA : ',data);
                    // state.centers = data.centers
                
                })
                .catch(err => console.log('ERROR : ',err)) 


        },
        updateCenter:async(state ,action)=>{
   
            const url = `${process.env.REACT_APP_API_URL}/settings/updateCenter`
            // console.log(action.payload)
            
            let body = {
                Desc: action.payload.centerDesc,
                centerHead: action.payload.centerHead,
                centerName: action.payload.centerName,
                centerImage:action.payload.centerImage,
                remove:action.payload.remove,
                email: action.payload.email,
                position:{
                    longitude:action.payload.longitude,
                    latitude:action.payload.latitude
                },
                phone: action.payload.phone,
                id:action.payload.id
            }
            // modifying the changes and deleting the old files
            await fetch(url, {
                method: 'put',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body) 
            })
            .then(res => res.json())
                .then(data => {       
                    console.log('DATA : ',data);

                })
            .catch(err => console.log('ERROR : ',err)) 
           
            // adding the new files
            if(action.payload.add){
                // console.log("new image added")
                    let formData = new FormData()
        
                    action.payload.add.forEach((file ,i) => {
                        formData.append('images',file)
                    })
    
                    let id = action.payload.id
        
                // Sending the images of the center
                await fetch(`${process.env.REACT_APP_API_URL}/settings/${id}/uploadCenterImage`, {
                            method: "post",
                            body:formData
                        })
                        .then(imageUploadResponse => {
                        if (!imageUploadResponse.ok) {
                            throw new Error('Network response was not ok');               
                        }
                        return imageUploadResponse.json();
                        })
                        .then(imageUploadData => {
                            
                        // console.log(imageUploadData);
                        })
                        .catch(err => {
                            if (err.json) {
                            err.json().then(errorMessage => {
                                console.error('Error:', errorMessage.message);
                            });
                            } else {
                            console.error('Error:', err);
                            }
                        });
                }
                                

            }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllCenters.pending)
        .addCase(getAllCenters.fulfilled, (state, action) => {
            // console.log("fulfilled")
            state = action.payload
            // console.log("state in mang:",state);
            return state
        })
        .addCase(getAllCenters.rejected, (state,action)=>{
            // console.log(action,"rejected")
        })
    }
})

export const {deleteCenter ,updateCenter } = manageCenters.actions
export default manageCenters.reducer