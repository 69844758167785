import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import axiosInstance from "../../../utils/AxiosInstance"
import produce from 'immer';

const roleFunction = {
    name: "",
    permissions: {}
}
const initialState = { roleFunction }

export const sendInfo = createAsyncThunk(
    "createRole/sendInfo",
    async ({ roleFunction }, thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/activities`
        const body = {
            name: roleFunction.name,
            permissions: roleFunction.permissions,
        }
        console.log("body:", body);
        axiosInstance.post(url, body)
            .then(res => console.log(res))
            .catch(err => thunkAPI.rejectWithValue(err.response))
    }
)

const roleCreationSlice = createSlice({
    name: "createRole",
    initialState,
    reducers: {
        RoleFunction: (state, action) => {
            if (action.payload.name)
                state.name = action.payload.name
            if (action.payload.permissions)
                state.permissions = action.payload.permissions
            console.log("stateL:", state);
            console.log("action.payload", action.payload);
            return
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendInfo.pending)
            .addCase(sendInfo.fulfilled, (state, action) => {
                console.log("Fulfilled")
                return produce(state, (RoleFunction) => {
                    RoleFunction.inputValue = action.payload;
                });
            })
            .addCase(sendInfo.rejected, (state, action) => {
                console.log("Rejected !")
            })
    }

})

export const { RoleFunction } = roleCreationSlice.actions
export default roleCreationSlice.reducer
