import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import axiosInstance from "../../../utils/AxiosInstance"

const examInfo = {
    session: "",
    field: "",
    level: "",
    center: ""
}
 
const personalInfo = {
    fname: "",
    lname: "",
    dob: "",
    email: "",
    address: "",
    nationality: "",
    region: "",
    gender: "",
    tel: "",
    maritalStatus:""
}
const parentInfo = {
    parentName: "",
    profession: "",
    parentEmail: "",
    parentPhone: "",
    parentAddress: "",
    personToContactName: "",
    personToContactPhone: "",
}
const paymentMethod = ""

const initialState = { examInfo, personalInfo, paymentMethod,parentInfo }

export const sendInfo = createAsyncThunk(
    "createCandidates/sendInfo",
    async ({ examInfo, personalInfo, paymentMethod,parentInfo }, thunkAPI) => {
        // const url = "https://iai-sms-bk.vercel.app/api/candidate/getAllCandidates"
        const url = `${process.env.REACT_APP_API_URL}/candidate/getAllCandidates`
        const body = {
            examInfo, personalInfo, paymentMethod,parentInfo
        }
        axiosInstance.post(url, body)
            .then(res => console.log(res))
            .catch(err => thunkAPI.rejectWithValue(err.response))
    }
)

const candidateSlice = createSlice({
    name: "createCandidates",
    initialState,
    reducers: {
        ExamInfo: (state, action) => {
            if (action.payload.session)
                state.examInfo.session = action.payload.session
            if (action.payload.center)
                state.examInfo.center = action.payload.center
            if (action.payload.field)
                state.examInfo.field = action.payload.field
            if (action.payload.level)
                state.examInfo.level = action.payload.level
        },
        PersonalInfo: (state, action) => {
            if (action.payload.lname)
                state.personalInfo.lname = action.payload.lname
            if (action.payload.fname)
                state.personalInfo.fname = action.payload.fname
            if (action.payload.dob)
                state.personalInfo.dob = action.payload.dob
            if (action.payload.address)
                state.personalInfo.address = action.payload.address
            if (action.payload.email)
                state.personalInfo.email = action.payload.email
            if (action.payload.gender)
                state.personalInfo.gender = action.payload.gender
            if (action.payload.nationality)
                state.personalInfo.nationality = action.payload.nationality
            if (action.payload.region)
                state.personalInfo.region = action.payload.region
            if (action.payload.tel)
                state.personalInfo.tel = action.payload.tel
            if (action.payload.maritalStatus)
            state.personalInfo.maritalStatus = action.payload.maritalStatus
            return
        },
        ParentInfo: (state, action) => {
            if (action.payload.parentName)
                state.parentInfo.parentName = action.payload.parentName
            if (action.payload.profession)
                state.parentInfo.profession = action.payload.profession
            if (action.payload.parentEmail)
                state.parentInfo.parentEmail = action.payload.parentEmail
            if (action.payload.parentAddress)
                state.parentInfo.parentAddress = action.payload.parentAddress
            if (action.payload.parentPhone)
                state.parentInfo.parentPhone = action.payload.parentPhone
            if (action.payload.personToContactName)
                state.parentInfo.personToContactName = action.payload.personToContactName
            if (action.payload.personToContactPhone)
                state.parentInfo.personToContactPhone = action.payload.personToContactPhone
            return
        },
        // Documents: (state, action) => {
        //     state.documents = action.payload
        // },
        Payments: (state, action) => {
            state.paymentMethod = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendInfo.pending)
            .addCase(sendInfo.fulfilled, (state, action) => {
                console.log("Fulfilled")
            })
            .addCase(sendInfo.rejected, (state, action) => {
                console.log("Rejected !")
            })
    }

})

export const { ParentInfo,ExamInfo, PersonalInfo, Documents, Payments } = candidateSlice.actions
export default candidateSlice.reducer
