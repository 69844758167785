import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/AxiosInstance"

export const getAllActivities = createAsyncThunk(
    "manageActivities/getAllActivities",
    async (thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/activities`

        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*"
        }
      try {
        const response = await fetch(url, {
            method: "GET",
            headers: headers,
        })
        console.log(response);
        return response.json()
    }
    catch(error) {
        thunkAPI.rejectWithValue(error.message)
    }
    }
)


const manageActivities = createSlice({
    name: "manageActivities",
    initialState: [],
    reducers: {
        UpdateActivity:async(state ,action)=>{
            console.log(action.payload)
            let id = action.payload.id
            console.log(id)
            let respond
            let body=action.payload
            // delete body.id

            if(action.payload.images){
                        let formData = new FormData()

                        action.payload.images.forEach((file ,i) => {
                            formData.append('images',file)
                        })
        
                // Sending the images of the activity
                await fetch(`${process.env.REACT_APP_API_URL}/activities/${id}/upload`, {
                            method: "post",
                            body:formData
                        })
                        .then(imageUploadResponse => {
                        if (!imageUploadResponse.ok) {
                            // toast.error('Network response was not ok');
                            throw new Error('Network response was not ok');               
                        }
                        return imageUploadResponse.json();
                        })
                        .then(imageUploadData => {
                            // toast.success('Activity Created Successfully');
                            
                        console.log(imageUploadData);
                        })
                        .catch(err => {
                            if (err.json) {
                            err.json().then(errorMessage => {
                                // toast.error(errorMessage.message);
                                console.error('Error:', errorMessage.message);
                            });
                            } else {
                            console.error('Error:', err);
                            }
                        });
            }

            const url = `${process.env.REACT_APP_API_URL}/activities/${id}`
            
           await axiosInstance.put(url,body)
            .then(res => {console.log(res);respond = res.data
            toast.success('Activity Update successfully')})
            .catch(err => console.log(err))

            return respond
        },

        DeleteActivity:async(state ,action)=>{
        
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllActivities.pending)
        .addCase(getAllActivities.fulfilled, (state, action) => {
            // console.log("fulfilled")
            state = action.payload
            // console.log("state in mang:",state);
            return state
        })
        .addCase(getAllActivities.rejected, (state,action)=>{
            console.log(action,"rejected")
        })
    }
})

export const {DeleteActivity ,UpdateActivity} = manageActivities.actions
export default manageActivities.reducer