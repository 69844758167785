/*

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import store from './store'
import { Provider } from 'react-redux'
import LandingLoader from "views/landingLoader";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next'
import './i18n';  // <- Important!

//Service Worker
// This file can be in your src/ directory and bundled with your application

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/serviceWorker.js') // Make sure this path is correct
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
}
registerServiceWorker();
function unregisterServiceWorker() {
  // In your main JavaScript file or where you register the Service Worker
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
      .then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
  }
}
// unregisterServiceWorker()




//Landing Page reference
const ConcourResult = lazy(() => import('./views/ConcourResults'));
const AuthLayout = lazy(() => import("./layouts/Auth.js"));
const AppAdminLayout = lazy(() => import("layouts/AppAdminLayout.js"));

const LandingPage = lazy(() => import("./views/landingPage"));
const AboutUs = lazy(() => import("views/aboutUs"));
const AllActivity = lazy(() => import("./views/allActivity"));
const Chatbot_Page = lazy(() => import("./views/chatbot/chatbot"));
const StudentConfirmation = lazy(() => import("./views/StudentConfirmation"));

const App = lazy(() => import("App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div>
          <LandingLoader />
        </div>}>
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/app-admin" render={(props) => <AppAdminLayout {...props} />} />

            <Route path="/campus-tour" render={(props) => <App />} />
            <Route path="/home" render={(props) => <LandingPage {...props} />} />
            <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
            <Route path="/allActivity" render={(props) => <AllActivity {...props} />} />
            <Route path="/chatbot" render={(props) => <Chatbot_Page {...props} />} />
            <Route path="/concour-results" render={(props) => <ConcourResult {...props} />} />
            <Route path="/student/:id" render={(props) => <StudentConfirmation {...props} />} />



            <Redirect from="/" to="/home" />
            {/* <Redirect from="/" to="/admin/index" /> */}
          </Switch>
        </ Suspense >
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);
