import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import axiosInstance from "../../../utils/AxiosInstance"
import produce from 'immer';

const centerInfo = {
    centerDesc: "",
    centerHead: "",
    centerName: "",
    email: [],
    image: [],
    latitude: "",
    longitude: "",
    phone: []
}
const initialState = { centerInfo }
// 13.49.77.158
// export const sendInfo = createAsyncThunk(
//     "createCenter/sendInfo",
//     async ({ centerInfo }, thunkAPI) => {
//         const url = "http://localhost:5000/api/activities"
//         console.log("Data at the redux store : ",centerInfo)


//         // axiosInstance.post(url, body)
//         //     .then(res => {
//         //         console.log(res)

//         //           if (res.data.message === "New Activity Created") {
//         //             console.log(centerInfo)
//         //             // return
//         //               let formData = new FormData()

//         //               centerInfo.images.forEach((file ,i) => {
//         //                   formData.append('images',file)
//         //               })

//         //             let id = res.data.newActivity._id;
//         //             console.log(id);

//         //         // Sending the images of the activity
//         //          fetch(`http://localhost:5000/api/activities/${id}/upload`, {
//         //                     method: "post",
//         //                     body:formData
//         //                 })
//         //                 .then(imageUploadResponse => {
//         //                 if (!imageUploadResponse.ok) {
//         //                     // toast.error('Network response was not ok');
//         //                     throw new Error('Network response was not ok');               
//         //                 }
//         //                 return imageUploadResponse.json();
//         //                 })
//         //                 .then(imageUploadData => {
//         //                     // toast.success('Activity Created Successfully');

//         //                 console.log(imageUploadData);
//         //                 })
//         //                 .catch(err => {
//         //                     if (err.json) {
//         //                     err.json().then(errorMessage => {
//         //                         // toast.error(errorMessage.message);
//         //                         console.error('Error:', errorMessage.message);
//         //                     });
//         //                     } else {
//         //                     console.error('Error:', err);
//         //                     }
//         //                 });
//         //             }

//         //     })
//         //     .catch(err => {
//         //         thunkAPI.rejectWithValue(err.response)
//         //         console.log(err)
//         //     })
//     }
// )

const centersCreationSlice = createSlice({
    name: "createCenter",
    initialState,
    reducers: {
        createCenter: (state, action) => {

            const url = `${process.env.REACT_APP_API_URL}/settings/createCenter`
            console.log("Data at the redux store : ", action)
            let body = {
                Desc: action.payload.centerDesc,
                centerHead: action.payload.centerHead,
                centerName: action.payload.centerName,
                email: action.payload.email,
                // centerImage: action.payload.files,
                position: {
                    longitude: action.payload.longitude,
                    latitude: action.payload.latitude
                },
                phone: action.payload.phone
            }

            axiosInstance.post(url, body)
                .then(res => {
                    console.log(res)
                    if (res.data.message === "New Center Created") {
                        let formData = new FormData()
                        action.payload.files.forEach((file, i) => {
                            formData.append('images', file)
                        })
                        let id = res.data.newCenter._id;
                        console.log(id);
                        // Sending the images of the center
                        fetch(`${process.env.REACT_APP_API_URL}/settings/${id}/uploadCenterImage`, {
                            method: "post",
                            body: formData
                        })
                            .then(imageUploadResponse => {
                                if (!imageUploadResponse.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return imageUploadResponse.json();
                            })
                            .then(imageUploadData => {

                                console.log(imageUploadData);
                            })
                            .catch(err => {
                                if (err.json) {
                                    err.json().then(errorMessage => {
                                        console.error('Error:', errorMessage.message);
                                    });
                                } else {
                                    console.error('Error:', err);
                                }
                            });
                    }

                })
                .catch(err => {
                    // thunkAPI.rejectWithValue(err.response)
                    console.log(err)
                })

            return
        },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(sendInfo.pending)
    //         .addCase(sendInfo.fulfilled, (state, action) => {
    //             console.log("Fulfilled")
    //             return produce(state, (centerInfo) => {
    //                 centerInfo.inputValue = action.payload;
    //             });
    //         })
    //         .addCase(sendInfo.rejected, (state, action) => {
    //             console.log("Rejected !")
    //         })
    // }

})

export const { createCenter } = centersCreationSlice.actions
export default centersCreationSlice.reducer

