import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import axiosInstance from "../../../utils/AxiosInstance"
import produce from 'immer';
import axios from "axios"

const concourInfo = {
    sessionName: "",
    field: [],
    level: [],
    dateOfConcour: "",
    center: [],
    passed: false,
    totalAmount: 14500,
    beginRegistration: "",
    endRegistration: "",
    concourRequirements: [],
    status: true 
    
}

const initialState = concourInfo;

export const sendUpdate = createAsyncThunk(
    "updateConcour/sendUpdate",
    async (concourInfo,thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/settings/updateSession`
        const body = {
            sessionName: concourInfo.sessionName,
            field: concourInfo.field,
            level: concourInfo.level,
            dateOfConcour: concourInfo.dateOfConcour,
            center: concourInfo.center,
            // passed: concourInfo.passed,
            totalAmount: concourInfo.totalAmount,
            beginRegistration: concourInfo.beginRegistration,
            endRegistration: concourInfo.endRegistration,
            requirement: concourInfo.concourRequirements,
            status: concourInfo.status,
        }

        console.log("body",body);
        console.log("url:",url);
        axiosInstance.post(url, body)
        .then(res => console.log(res))
        .catch(err => thunkAPI.rejectWithValue(err.response))
    }
)

const concourUpdateSlice = createSlice({
    name: "updateConcour",
    initialState,
    reducers: {
        ConcourUpdateInfo: (state, action) => {
            if (action.payload.sessionName)
                state.sessionName = action.payload.sessionName
            if (action.payload.field)
                state.field = action.payload.field
            if (action.payload.level)
                state.level = action.payload.level
            if (action.payload.dateOfConcour)
                state.dateOfConcour = action.payload.dateOfConcour
            if (action.payload.center)
                state.center = action.payload.center
            if (action.payload.passed)
                state.passed = action.payload.passed
            if (action.payload.totalAmount)
                state.totalAmount = action.payload.totalAmount
            if (action.payload.beginRegistration)
                state.beginRegistration = action.payload.beginRegistration
            if (action.payload.endRegistration)
                state.endRegistration = action.payload.endRegistration
            if (action.payload.concourRequirements)
                state.concourRequirements = action.payload.concourRequirements
            if (action.payload.status)
                state.status = action.payload.status
            return
        },

        ConcourDelete: (state,action) => {
            const url = `${process.env.REACT_APP_API_URL}/settings/deleteSession`
            console.log("url:",url);
            axiosInstance.post(url,action.payload)
            .then(res => console.log(res))
            .catch(err => (err.response))
 
        }
    },
    extraReducers: (builder) => {
        builder 
        .addCase(sendUpdate.pending)
        .addCase(sendUpdate.fulfilled, (state, action) => {
            console.log("Fulfilled")
            console.log("action.Payload",action.payload);
            return produce(state,(concourUpdate)=>{
                concourUpdate = action.payload;
            });
        })
        .addCase(sendUpdate.rejected, (state, action) => {
            console.log("Rejected !")
        })
    }
    
})

export const {ConcourUpdateInfo,ConcourDelete} = concourUpdateSlice.actions
export default concourUpdateSlice.reducer