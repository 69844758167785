import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/Redux/user/userSlice'
import manageCandidatesReducer from "./features/Redux/Candidate/manageCandidates"
import createCandidateReducer from "./features/Redux/Candidate/createCandidates"
import managePersonnelReducer from "./features/Redux/PersonnelSlice/managePersonnel"
import createPersonnelReducer from "./features/Redux/PersonnelSlice/createPersonnel"
import updatePersonnelReducer from "./features/Redux/PersonnelSlice/updatePersonnel"
import manageConcourReducer from "./features/Redux/Concours/manageConcour"
import createConcourReducer from "./features/Redux/Concours/concourCreation"
import updateConcourReducer from "./features/Redux/Concours/updateConcour"
import manageActivitiesReducer from "./features/Redux/ActivitiesSlice/manageActivities"
import createActivitiesReducer from "./features/Redux/ActivitiesSlice/createActivities"
import updateActivitiesReducer from "./features/Redux/ActivitiesSlice/manageActivities"
import manageRoleReducer from "./features/Redux/RoleSlice/manageRole"
import createRoleReducer from "./features/Redux/RoleSlice/createRole"
import managePermissionReducer from "./features/Redux/PermissionSlice/managePermission"
import createCenterReducer from 'features/Redux/CenterSlice/createCenter'
import manageCenterReducer from 'features/Redux/CenterSlice/manageCenter'


export default configureStore({
  reducer: {
    user: userReducer,
    manageCandidates: manageCandidatesReducer,
    createCandidates: createCandidateReducer,
    managePersonnels: managePersonnelReducer,
    createPersonnels: createPersonnelReducer,
    updatePersonnels: updatePersonnelReducer,
    manageConcours: manageConcourReducer,
    createConcours: createConcourReducer,
    updateConcours: updateConcourReducer,
    manageActivities: manageActivitiesReducer,
    createActivities: createActivitiesReducer,
    updateActivities: updateActivitiesReducer,
    manageRole: manageRoleReducer,
    managePermission: managePermissionReducer,
    createRole: createRoleReducer,
    createCenter:createCenterReducer,
    manageCenter:manageCenterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})