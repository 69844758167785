import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import axiosInstance from "../../../utils/AxiosInstance"
import produce from 'immer';

const personalInfo = {
    Fname: "",
    Lname: "",
    Dob: "",
    Gender: "",
    Email: "",
    phoneNumber: "",
    Address: "",
    nantionanlity: "",
    region: "",
}
const schoolInfo = {
    department: [],
    title: "",
    role: [],
    contract: [],
    status: ""
}
const personnelDocument = {
    images: []
}
const documents = {
    images: []
}
const contact = {
    name: "",
    phone: "",
    profression: "",
    gender: "",
    email: ""
}
const personnelFunction = {
    Fname: "",
    Lname: "",
    Dob: "",
    Email: "",
    Address: "",
    Gender: "",
    nantionanlity: "",
    region: "",
    accountType: "Personnel",
    department: [],
    role: [],
    title: "",
    phoneNumber: "",
    contract: [],
    status: "",
}
const initialState = { personnelFunction, personalInfo, schoolInfo, personnelDocument, documents, contact }

export const sendInfo = createAsyncThunk(
    "createPersonnel/sendInfo",
    async ({ personalInfo, schoolInfo, contact }, thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/personnel/createPerso`
        const body = { personalInfo, schoolInfo, contact }
        console.log("body", body);
        
        axiosInstance.post(url, body)
            .then(async (res) => {
                console.log(res);
            })
            .catch(err => thunkAPI.rejectWithValue(err.response))
    }
)

const personnelCreationSlice = createSlice({
    name: "createPersonnel",
    initialState,
    reducers: {
        PersonnelFunction: (state, action) => {
            // state.personnelFunction =  action.payload
            if (action.payload.Lname)
                state.personnelFunction.Lname = action.payload.Lname
            if (action.payload.Fname)
                state.personnelFunction.Fname = action.payload.Fname
            if (action.payload.Dob)
                state.personnelFunction.Dob = action.payload.Dob
            if (action.payload.Address)
                state.personnelFunction.Address = action.payload.Address
            if (action.payload.Email)
                state.personnelFunction.Email = action.payload.Email
            if (action.payload.Gender)
                state.personnelFunction.Gender = action.payload.Gender
            if (action.payload.nantionanlity)
                state.personnelFunction.nantionanlity = action.payload.nantionanlity
            if (action.payload.region)
                state.personnelFunction.region = action.payload.region
            if (action.payload.department)
                state.personnelFunction.department = action.payload.department
            if (action.payload.role)
                state.personnelFunction.role = action.payload.role
            if (action.payload.title)
                state.personnelFunction.title = action.payload.title
            if (action.payload.phoneNumber)
                state.personnelFunction.phoneNumber = action.payload.phoneNumber
            if (action.payload.contract)
                state.personnelFunction.contract = action.payload.contract
            if (action.payload.status)
                state.personnelFunction.status = action.payload.status
            return
        },
        PersonalInfo: (state, action) => {
            if (action.payload.Lname)
                state.personalInfo.Lname = action.payload.Lname
            if (action.payload.Fname)
                state.personalInfo.Fname = action.payload.Fname
            if (action.payload.Dob)
                state.personalInfo.Dob = action.payload.Dob
            if (action.payload.Gender)
                state.personalInfo.Gender = action.payload.Gender
            if (action.payload.Email)
                state.personalInfo.Email = action.payload.Email
            if (action.payload.phoneNumber)
                state.personalInfo.phoneNumber = action.payload.phoneNumber
            if (action.payload.Address)
                state.personalInfo.Address = action.payload.Address
            if (action.payload.nantionanlity)
                state.personalInfo.nantionanlity = action.payload.nantionanlity
            if (action.payload.region)
                state.personalInfo.region = action.payload.region
            return
        },
        SchoolInfo: (state, action) => {
            if (action.payload.department)
                state.schoolInfo.department = action.payload.department
            if (action.payload.role)
                state.schoolInfo.role = action.payload.role
            if (action.payload.title)
                state.schoolInfo.title = action.payload.title
            if (action.payload.contract)
                state.schoolInfo.contract = action.payload.contract
            if (action.payload.status)
                state.schoolInfo.status = action.payload.status
            return
        },
        Contact: (state, action) => {
            if (action.payload.name)
                state.contact.name = action.payload.name
            if (action.payload.phone)
                state.contact.phone = action.payload.phone
            if (action.payload.gender)
                state.contact.gender = action.payload.gender
            if (action.payload.email)
                state.contact.email = action.payload.email
            if (action.payload.profression)
                state.contact.profression = action.payload.profression
            return
        },
        PersonnelDocument: (state, action) => {
            console.log(action.payload);
            if (action.payload.files) {
                state.documents.images = action.payload.files
                state.personnelDocument.images = []
                state.documents.images.map((items) => {
                    state.personnelDocument.images.push(items.name)
                })
                console.log(state.personnelDocument.images);
                console.log(state);
            }
            return
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendInfo.pending)
            .addCase(sendInfo.fulfilled, (state, action) => {
                console.log("Fulfilled")
                return produce(state, (PersonnelFunction) => {
                    PersonnelFunction.inputValue = action.payload;
                });
            })
            .addCase(sendInfo.rejected, (state, action) => {
                console.log("Rejected !")
            })
    }

})

export const { PersonnelFunction, PersonalInfo, SchoolInfo, PersonnelDocument, Contact } = personnelCreationSlice.actions
export default personnelCreationSlice.reducer
