import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance"

export const getAllRole = createAsyncThunk(
    "manageRole/getAllRole",
    async (thunkAPI) => {
        // const url = "http://13.49.77.158:5000/api/settings/getAllSession"
        const url = `${process.env.REACT_APP_API_URL}/settings/getAllRole`

        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*"
        }
        try {
            let respond
            await fetch(url, {
                method: "get",
                headers: headers
            })
                .then(res => res.json())
                .then(data => {
                    respond = data
                    // console.log(data)
                })
                .catch(err => console.log)
            console.log(respond.roles)

            return respond.roles
        }
        catch (error) {
            console.log("the error is :")
            console.error(error);

            thunkAPI.rejectWithValue(error.message)
        }
    }
)

const manageRole = createSlice({
    name: "manageRole",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllRole.pending)
            .addCase(getAllRole.fulfilled, (state, action) => {
                console.log("fulfilled")
                state = action.payload
                // console.log("state in mang:", state);
                return state
            })
            .addCase(getAllRole.rejected, (state, action) => {
                console.log(action, "rejected")
            })
    }
})

export default manageRole.reducer