import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // to load translations using http
  .use(LanguageDetector) // automatically detect user language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false // not needed for React
    }
  });

export default i18n;