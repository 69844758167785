import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import axiosInstance from "../../../utils/AxiosInstance"
import produce from 'immer';
import { toast } from "react-toastify";

const activitiesFunction = {
    en: {},
    fr: {},
    images: [],
    dateOfevent: ""
}
const initialState = { activitiesFunction }
// 13.49.77.158
export const sendInfo = createAsyncThunk(
    "createActivities/sendInfo",
    async ({ activitiesFunction }, thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/activities`
        console.log("Data at the redux store : ", activitiesFunction)

        // return
        const body = {
            en: activitiesFunction.en,
            fr: activitiesFunction.fr,
            dateOfevent: activitiesFunction.dateOfevent,
            userId: 1234656789
            // id:'64d10b54702b9ba3b79ba5ae'
        }
        // console.log("body before sending:", body);
        // console.log()
        await axiosInstance.post(url, body)
            .then(async (res) => {
                console.log(res)

                if (res.data.message === "New Activity Created") {
                    // console.log(activitiesFunction)
                    // return
                    if (activitiesFunction.images) {
                        let formData = new FormData()
                        activitiesFunction.images.forEach((file, i) => {
                            formData.append('images', file)
                        })
                        let id = res.data.newActivity._id;
                        console.log(id);
                        // Sending the images of the activity
                        await fetch(`${process.env.REACT_APP_API_URL}/activities/${id}/upload`, {
                            method: "post",
                            body: formData
                        })
                            .then(imageUploadResponse => {
                                if (!imageUploadResponse.ok) {
                                    // toast.error('Network response was not ok');
                                    throw new Error('Network response was not ok');
                                }
                                return imageUploadResponse.json();
                            })
                            .then(imageUploadData => {
                                // toast.success('Activity Created Successfully');
                                toast.success("Activity Created Successfully");
                                console.log(imageUploadData);
                            })
                            .catch(err => {
                                if (err.json) {
                                    err.json().then(errorMessage => {
                                        // toast.error(errorMessage.message);
                                        console.error('Error:', errorMessage.message);
                                    });
                                } else {
                                    console.error('Error:', err);
                                }
                            });
                    } else {
                        toast.success("Activity Created Successfully");
                    }
                }

            })
            .catch(err => {
                thunkAPI.rejectWithValue(err.response)
                console.log(err)
            })
    }
)

const activitiesCreationSlice = createSlice({
    name: "createActivities",
    initialState,
    reducers: {
        ActivitiesFunction: (state, action) => {
            if (action.payload.en)
                state.activitiesFunction.en = action.payload.en

            if (action.payload.fr)
                state.activitiesFunction.fr = action.payload.fr

            if (action.payload.images)
                state.activitiesFunction.images = action.payload.images

            if (action.payload.dateOfevent)
                state.activitiesFunction.dateOfevent = action.payload.dateOfevent

            console.log("action.payload received", action.payload)
            return
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendInfo.pending)
            .addCase(sendInfo.fulfilled, (state, action) => {
                console.log("Fulfilled")
                return produce(state, (ActivitiesFunction) => {
                    ActivitiesFunction.inputValue = action.payload;
                });
            })
            .addCase(sendInfo.rejected, (state, action) => {
                console.log("Rejected !")
            })
    }

})

export const { ActivitiesFunction } = activitiesCreationSlice.actions
export default activitiesCreationSlice.reducer
