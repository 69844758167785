import React from 'react'

function LandingLoader(){
    return(
        <React.Fragment>
            <div style={{
                position:"absolute",
                zIndex:"2000",
                height:"100%",
                width:"100%",
                backgroundColor:"#b4cd6b",
                paddingTop:"15%",
                color:"white",
                
            }}>
            <center>                
                <img src={require('../assets/img/brand/download.jpeg')} style={{borderRadius:"20px",boxShadow:"2px 3px 10px grey"}}/><br/>                
                <br />
                <h1 style={{color:"white"}}>Loading...</h1>
                <img height="100px" src={require('../assets/img/load-1110.gif')} />
            </center>
            </div>
        </React.Fragment>
    )
}

export default LandingLoader