import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance"

export const getAllPermission = createAsyncThunk(
    "managePermission/getAllPermission",
    async (thunkAPI) => {
        // const url = "http://13.49.77.158:5000/api/settings/getAllRole"
        const url = `${process.env.REACT_APP_API_URL}/settings/getAllPermission`

        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*"
        }
        try {
            let respond
            await fetch(url, {
                method: "get",
                headers: headers
            })
                .then(res => res.json())
                .then(data => {
                    respond = data
                    // console.log(data)
                })
                .catch(err => console.log)
            console.log(respond.permissions)

            return respond.permissions
        }
        catch (error) {
            console.log("the error is :")
            console.error(error);

            thunkAPI.rejectWithValue(error.message)
        }
    }
)

const managePermission = createSlice({
    name: "managePermission",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllPermission.pending)
            .addCase(getAllPermission.fulfilled, (state, action) => {
                console.log("fulfilled")
                state = action.payload
                return state
            })
            .addCase(getAllPermission.rejected, (state, action) => {
                console.log(action, "rejected")
            })
    }
})

export default managePermission.reducer