import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance"


// export const getAllPersonnel = createAsyncThunk(
//     "managePersonnel/getAllPersonnel",
//     async (thunkAPI) => {
//         const url = "http://35.188.194.141:5000/api/candidate/getPerso"
//         axiosInstance.get(url)
//         .then(resp => resp)
//         .catch(error => thunkAPI.rejectWithValue(error.message))
//     }
// )
export const getAllPersonnel = createAsyncThunk(
    "managePersonnel/getAllPersonnel",
    async (thunkAPI) => {
        // const url = "http://35.188.194.141:5000/api/personnel/getPerso"
        // const url = "http://localhost:8000/api/personnelDatas"
        const url = `${process.env.REACT_APP_API_URL}/personnel/getAllPerso`
        const token = localStorage.getItem("token");

        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*",
            "x-access-token": token
        }
        try {
            let respond
            await fetch(url, {
                method: "get",
                headers: headers
            })
                .then(res => res.json())
                .then(data => {
                    respond = data
                    console.log("all persodata",data)
                })
                .catch(err => console.log)
            console.log(respond.personnels)

            return respond.personnels
        }
        catch (error) {
            console.log("the error is :")
            console.error(error);

            thunkAPI.rejectWithValue(error.message)
        }
    }
)

const managePersonnel = createSlice({
    name: "managePersonnel",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllPersonnel.pending)
            .addCase(getAllPersonnel.fulfilled, (state, action) => {
                console.log("fulfilled")
                state = action.payload
                // console.log("state", state);
                return state
            })
            .addCase(getAllPersonnel.rejected, (state, action) => {
                console.log(action, "rejected")
            })
    }
})

export default managePersonnel.reducer