import axios from "axios"

axios.interceptors.request.use(function (config){
    config.headers.Authorization = 'Beaere 123'
});

const axiosInstance = axios.create({
    baseURL: "https://c98d69f7-cbb0-457f-a63f-aefb4fdd4982.mock.pstmn.io/",
    headers: {
        Accept: 'application/json'
    }
});

export default axiosInstance;