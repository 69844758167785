import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import userList from '../../../data/users'
import axiosInstance from '../../../utils/AxiosInstance';
import axios from "axios";
const initialState = {
    userInfo: {
        id: '',
        email: 'ty',
        profilePicture: 'gg',
        firstName: '', 
        lastName: '',
        authenticated:false,
        // password:''
    },
    status: 'idle',
    error: null
}

export const loginApi = createAsyncThunk(
  "login/loginApi",
   async (loginInfo ,thunkAPI) => {
      // const url = "http://35.188.194.141:5000/api/concour/createConcour"
      // const url = "http://localhost:5000/api/concour/createConcour"
      const url = `${process.env.REACT_APP_API_URL}/auths/login`

      const body = {
        email:loginInfo.userInfo.email,
        password:loginInfo.userInfo.password
      }

      console.log(loginInfo)
      axiosInstance.post(url, body)
      .then(resp => console.log(resp))
      .catch(err => thunkAPI.rejectWithValue(err.response))
   }
)


// export const loginApi = createAsyncThunk('user/loginApi', async (payload) => {
//   const response = await axiosInstance.post('login', payload);
//   return response;
// })


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login(state, action) {
            // state.push(action.payload)
            const registerdUser = userList.find(user => user.email === action.payload.email && user.password === action.payload.password);
            if (registerdUser) {
                state.userInfo.id = registerdUser.id;
                state.userInfo.email = registerdUser.email;
                state.userInfo.firstName = registerdUser.firstName;
                state.userInfo.lastName = registerdUser.lastName;
                state.userInfo.authenticated = true;
                console.log('successful');
            }else {
                console.log('unsuccessful');
            }
            console.log(action);
          },
          register(state, action) {
            // state.push(action.payload)
            // userList.push(action.payload)

            const url = `${process.env.REACT_APP_API_URL}/api/auths/registerCandidate`
            // state.userInfo.email = action.payload.email
 
            fetch(url ,{
              method:"POST",
              headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Origin": "*",
                  },
              body:JSON.stringify({
                email:action.payload.email,
                password:action.payload.password,
                passwordConfirmation:action.payload.passwordConfirmation,
                checkbox:action.payload.checkbox,
              })
            })
            .then(respond => respond.json())
            // .then(data =>{ result = data})
            // .catch(error => result = error)
            .then(data =>{console.log(data) })
            .catch(error => console.log( error))


          }
    },
    extraReducers(builder) {
        builder
          .addCase(loginApi.pending, (state, action) => {
            console.log(action);
            state.status = 'loading'
          })
          .addCase(loginApi.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.userInfo.authenticated = true;
            state.userInfo.id = action.payload.data.id;
            state.userInfo.email = action.payload.data.email;
            state.userInfo.firstName = action.payload.data.firstName;
            state.userInfo.lastName = action.payload.data.lastName;
            state.userInfo.profilePicture = action.payload.data.profilePicture;
            console.log(action);
            // state.posts = state.posts.concat(action.payload)
          })
          .addCase(loginApi.rejected, (state, action) => {
            console.log(action);
            state.status = 'failed'
            state.error = action.error.message
          })
      }
})

export const { login,register } = userSlice.actions


export default userSlice.reducer