import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axiosInstance from "../../../utils/AxiosInstance"
import produce from 'immer';


const concourInfo = {
    sessionName: "",
    field: [],
    level: [],
    dateOfConcour: "",
    center: [],
    passed: false,
    totalAmount: 14500,
    beginRegistration: "",
    endRegistration: "",
    concourRequirements: [],
    status: true
}

const initialState = concourInfo

export const sendInfo = createAsyncThunk(
    "concourCreation/createConcour",
    async (concourInfo, thunkAPI) => {
        const url = `${process.env.REACT_APP_API_URL}/settings/createSession`
        const body = {
            sessionName: concourInfo.sessionName,
            field: concourInfo.field,
            level: concourInfo.level,
            dateOfConcour: concourInfo.dateOfConcour,
            center: concourInfo.center,
            // passed: concourInfo.passed,
            totalAmount: concourInfo.totalAmount,
            beginRegistration: concourInfo.beginRegistration,
            endRegistration: concourInfo.endRegistration,
            requirement: concourInfo.concourRequirements,
            status: concourInfo.status,
        }

        console.log("This is the concour info before sending")
        console.log(body)
        axiosInstance.post(url, body)
            .then(resp => console.log(resp))
            .catch(err => thunkAPI.rejectWithValue(err.response))
    }
)

const concourCreationSlice = createSlice({
    name: "concourCreation",
    initialState,
    reducers: {
        ConcourCreation: (state, action) => {
            if (action.payload.sessionName)
                state.sessionName = action.payload.sessionName
            if (action.payload.field)
                state.field = action.payload.field
            if (action.payload.level)
                state.level = action.payload.level
            if (action.payload.dateOfConcour)
                state.dateOfConcour = action.payload.dateOfConcour
            if (action.payload.center)
                state.center = action.payload.center
            if (action.payload.passed)
                state.passed = action.payload.passed
            if (action.payload.totalAmount)
                state.totalAmount = action.payload.totalAmount
            if (action.payload.beginRegistration)
                state.beginRegistration = action.payload.beginRegistration
            if (action.payload.endRegistration)
                state.endRegistration = action.payload.endRegistration
            if (action.payload.concourRequirements)
                state.concourRequirements = action.payload.concourRequirements
            if (action.payload.status)
                state.status = action.payload.status
                console.log("stateL:",state);
            return
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendInfo.pending)
            .addCase(sendInfo.fulfilled, (state, action) => {
                console.log("Fulfilled")
                return produce(state, (ActivitiesFunction) => {
                    ActivitiesFunction.inputValue = action.payload;
                });
            })
            .addCase(sendInfo.rejected, (state, action) => {
                console.log("Rejected !")
            })
    }
})

export const { ConcourCreation } = concourCreationSlice.actions
export default concourCreationSlice.reducer